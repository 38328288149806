/* https://stackoverflow.com/a/71889746/4224964 */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.app {
	--bg-shade-1: initial;
	--bg-shade-2: initial;
	--bg-shade-3: initial;
	--bg-shade-4: initial;
}

.bg-shade-1 {
	background: var(--bg-shade-1);
}
.bg-shade-2 {
	background: var(--bg-shade-2);
}
.bg-shade-3 {
	background: var(--bg-shade-3);
}
.bg-shade-4 {
	background: var(--bg-shade-4);
}
.border-shade-1 {
	border-color: var(--bg-shade-1);
}
.border-shade-2 {
	border-color: var(--bg-shade-2);
}
.border-shade-3 {
	border-color: var(--bg-shade-3);
}
.border-shade-4 {
	border-color: var(--bg-shade-4);
}

.code-badge {
	background: pink !important;
}

.code-badge-language {
	color: orangered !important;
}

.code-badge-copy-icon{
	font-size: 1.6em !important;
}

pre{
	padding: 20px!important;
	margin: 0px!important;
}